import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AuthUtils } from '@sbd-account-portal/app/core/auth/auth.utils';
// import { UserService } from '@sbd-account-portal/app/core/user/user.service'
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@sbd-account-portal/app/environments/environment';

@Injectable({ providedIn: 'any' })
export class AccountPortalAuthService {
  private _authenticated = false;
  private _forgot_pass_email_filled = false;
  private _xbadminCipx: string | null = null;
  private _xbuserCipx: string | null = null;
  private _sessionId: string | null = null;
  private apiUrl = environment.apiLocalUrl;
  tokenNotRenewable = signal<boolean>(false) ;

  /**
   * Constructor
   */
  constructor(
    private _cookieService: CookieService,
    private _httpClient: HttpClient,
    // private _userService: UserService
  ) {
    // this._xbadminCipx = this._cookieService.get('xbadmin_cipx');
    // this._xbuserCipx = this._cookieService.get('xbauser_cipx');
    // this._sessionId = this._cookieService.get('PHPSESSID');

    // // this._authenticated = this._xbadminCipx != null && this._xbuserCipx != null && this._sessionId != null &&
    // //   this._xbadminCipx.length > 0 && this._xbadminCipx.length > 0 && this._sessionId.length > 0;

    // this._authenticated = this._xbadminCipx != null && this._sessionId != null &&
    //   this._xbadminCipx.length > 0 && this._xbadminCipx.length > 0 && this._sessionId.length > 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  get sessionId(): string {
    return this._cookieService.get('PHPSESSID') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post(this.apiUrl + '/auth/forgot-password', { email });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: any): Observable<any> {
    return this._httpClient.post(this.apiUrl + '/auth/reset-password', data);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient.post(this.apiUrl + '/auth/login', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        // this.accessToken = response.data.auth.auth.AccessToken;

        // 2024-07-12 DM: If we are valid we now go to MFA page.
        // if(response && response?.success) {
        //   this._authenticated = true;
        //   this._cookieService.set('accessToken', response?.data?.auth?.auth?.AccessToken);
        // }

        // Store the user on the user service
        // this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient.post('api/auth/sign-in-with-token', {
      accessToken: this.accessToken,
    }).pipe(
      catchError(() =>

        // Return false
        of(false),
      ),
      switchMap((response: any) => {

        // Replace the access token with the new one if it's available on
        // the response object.
        //
        // This is an added optional step for better security. Once you sign
        // in using the token, you should generate a new one on the server
        // side and attach it to the response object. Then the following
        // piece of code can replace the token with the refreshed one.
        if (response.accessToken) {
          this.accessToken = response.accessToken;
        }

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        // this._userService.user = response.user;

        // Return true
        return of(true);
      }),
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    this._cookieService.deleteAll();

    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(data: any): Observable<any> {
    return this._httpClient.post(this.apiUrl + '/auth/register', data);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    if (this.sessionId) {
      this._authenticated = true;
    }
    return this._httpClient.get<{ success: boolean }>(`${this.apiUrl}/auth/check`).pipe(
      map(response => response.success === true),
      catchError(() => of(false))
    );
  }


  /**
   * Check the authentication status
   */
  checkIfUserEmailPrefilled(): Observable<any> {
    return this._httpClient.get(this.apiUrl + '/auth/forgot-password/check-email').pipe(
      map((response: any) => {
        return response?.success;
      }),
      catchError((error) => {
        // console.error('Error checking if email is prefilled', error);
        return of(false);
      }),
    );
  }


  /**
   * Check the authentication MFA status
   */
  checkIfUserAccountRequiredMFA(): Observable<any> {
    return this._httpClient.get(this.apiUrl + '/auth/check-account-status/mfa').pipe(
      map((response: any) => {
        if (!response?.success || !response?.data?.continue) {
          return false;
        }
        if (Object.keys(response?.data).includes('waiting_time')) {
          this._cookieService.set('idle-time', +response.data.waiting_time <= 0 ? 0 : response.data.waiting_time.toString());
        }
        return true;
      }),
      catchError((error) => {
        // console.error('Error checking if account awaiting MFA verifcation', error);
        return of(false);
      }),
    );
  }

  /**
   * Resend MFA Code
   */
  resendCode(): Observable<any> {
    let resp = { success: false, message: '', wait: 0 };

    return this._httpClient.get(this.apiUrl + '/auth/resend-verification-code/signup').pipe(
      map((response: any) => {
        if (response?.success) {
          this._cookieService.set('idle-time', '300');
          resp.success = true;
          resp.wait = 300;
        } else {
          if (Object.keys(response?.data).includes('waiting_time')) {
            this._cookieService.set('idle-time', response.data.waiting_time);
            resp.wait = response.data.waiting_time;
          }
        }
        resp.message = response?.message;
        return resp;
      }),
      catchError((error) => {
        // console.error('Error checking if account awaiting MFA verifcation', error);
        return of(false);
      }),
    );
  }

  /**
   * Submit Signup MFA Code
   */
  submitSignupMFACode(code: number): Observable<any> {

    return this._httpClient.post(this.apiUrl + '/auth/confirm-signup', { code }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        // console.error('Error validating your validation code', error);
        return of(false);
      }),
    );
  }


  /**
   * Submit Authentication MFA Code
   */
  submitAuthenticationMFACode(code: number): Observable<any> {

    return this._httpClient.post(this.apiUrl + '/auth/validate-mfa-token', { code }).pipe(
      map((response: any) => {
        if(response && response?.success) {
          this._authenticated = true;
          this.accessToken = response?.data?.auth?.auth?.AccessToken;
        }
        return response;
      }),
      catchError((error) => {
        // console.error('Error validating your authentication code', error);
        return of(false);
      }),
    );
  }

  /**
   * Check Unique Email Data
   */
  checkUniqueEmailForSignup(email: string): Observable<any> {
    return this._httpClient.post(this.apiUrl + '/auth/unique-signup', { email }).pipe(
      map((response: any) => {
        return response?.success;
      }),
      catchError((error) => {
        // console.error('Error validating your validation code', error);
        return of(false);
      }),
    );
  }


  isTokenRefreshable(): Observable<boolean> {
    return this._httpClient.get<any>(environment.apiLocalUrl + '/auth/session-alive').pipe(
      map((r) => {
        if (r?.success) {
          return true;
        }
        return false;
      }));
  }
}
